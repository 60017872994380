import Masonry from 'masonry-layout'; // eslint-disable-line
export default class Gallery {
  constructor(el) {
    this.elGallery = el;
    console.log(this.elGallery);
  }
  static init(selector) {
    [...document.querySelectorAll(selector)].forEach(el => new this(el));
  }
}
