import { CookieMessage, Gallery } from './components';

class App {
    constructor() {
        window.addEventListener('load', () => {
            CookieMessage.init('.js-cookie-message');
            Gallery.init('.js-gallery');
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
